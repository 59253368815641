<script>
    import { onMount } from "svelte";
    import Router, { link } from "svelte-spa-router";
    import Privacy from "./Privacy.svelte";
    import Terms from "./Terms.svelte";


    let privacyDialog;
    let termsDialog;

    function openPrivacyDialog() {
        if (privacyDialog) {
            privacyDialog.showModal();
            resetModalScroll(privacyDialog);
        }
    }

    function openTermsDialog() {
        if (termsDialog) {
            termsDialog.showModal();
            resetModalScroll(termsDialog);
        }
    }

    function resetModalScroll(dialog) {
        const modalContent = dialog.querySelector(".modal-content");
        if (modalContent) {
            modalContent.scrollTop = 0;
        }
    }

    function closeDialog(dialog) {
        dialog.close();
        window.location.hash = "";
    }

    // Add event listener to detect hash changes
    window.addEventListener("hashchange", () => {
        if (window.location.hash === "#/privacy") {
            openPrivacyDialog();
        } else if (window.location.hash === "#/terms") {
            openTermsDialog();
        } else {
            // Close dialogs if the hash is not #/privacy or #/terms
            if (privacyDialog && privacyDialog.open) {
                closeDialog(privacyDialog);
            }
            if (termsDialog && termsDialog.open) {
                closeDialog(termsDialog);
            }
        }
    });

    onMount(() => {
        console.log({ currentHash: window.location.hash });
        // Check initial hash after component has mounted
        if (window.location.hash === "#/privacy") {
            openPrivacyDialog();
        } else if (window.location.hash === "#/terms") {
            openTermsDialog();
        }
    });
</script>

<main>
    <img src="/assets/logo.png" alt="Brewscout Logo" aria-label="Brewscout Logo" />

    <div class="links">
        <a href="#/privacy" use:link>Privacy Policy</a> |
        <a href="#/terms" use:link>Terms & Conditions</a>
    </div>

    <p class="links-small">
        BrewScout is a <a href="https://renepot.net" target="_blank">Topener</a> company.
    </p>

    <!-- Privacy Dialog -->
    <dialog bind:this={privacyDialog} class="modal">
        <div class="modal-content">
            <button class="close-button" on:click={() => closeDialog(privacyDialog)}>✖</button>
            <Privacy />
        </div>
    </dialog>

    <!-- Terms Dialog -->
    <dialog bind:this={termsDialog} class="modal">
        <div class="modal-content">
            <button class="close-button" on:click={() => closeDialog(termsDialog)}>✖</button>
            <Terms />
        </div>
    </dialog>
</main>

<style>
    html,
    body {
        margin: 0;
        padding: 0;
        overflow: hidden;
        height: 100%;
    }
    main {
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100%;
    }
    img {
        max-width: 500px;
        max-height: 100%;
        margin-bottom: 20px; /* Add space between logo and links */
    }
    .links {
        margin-top: 20px;
        font-size: 0.9em;
    }
    .links-small {
        margin-top: 20px;
        font-size: 0.8em;
    }
    button {
        margin: 0 10px;
        padding: 8px 12px;
        background-color: #333;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 4px;
    }
    dialog {
        padding: 0;
        border: none;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        width: 80%;
        max-width: 600px;
        max-height: 80vh;
        overflow: hidden;
    }

    /* Backdrop styling */
    dialog::backdrop {
        background: rgba(0, 0, 0, 0.5); /* Blackout background */
        z-index: 999; /* Ensures it's above content but below the modal */
    }

    /* Disable body scrolling when modal is open */
    html,
    body {
        overflow: hidden; /* Ensures no scrolling in the background */
    }
    .modal {
        width: 80%;
        max-width: 600px;
        max-height: 80%;
        overflow: hidden;
        position: fixed;
        /* top: 50%; */
        /* left: 50%; */
        transform: translate(-50%, -50%);
        z-index: 1000; /* Ensure modal content is above everything else */
        margin: 30px;
    }
    .modal-content {
        max-height: calc(80vh - 40px); /* Adjust for close button */
        overflow-y: auto;
        padding: 20px;
    }
    .close-button {
        position: sticky;
        top: 10px;
        float: right;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        color: #333;
        z-index: 1;
    }
</style>
